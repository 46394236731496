// stores/myStore.js
import { defineStore } from 'pinia';
import { useSocketService } from '../../services/socketService';
import { Socket } from 'socket.io-client';
import { setBlockTracking } from 'vue';

const apiUrl = import.meta.env.VITE_API_URL;

interface Message {
  id: number;
  text: string;
}

interface SocketState {
  socket: Socket | null;
  messages: Message[];
}

export const useAppStore = defineStore('appStore', {
  state: () => ({
    socket: null,
    onlineMasters: 0,
    totalMasters: 0,
    masters: Array<any>(),
    calendarEvents: Array<any>(),
    showLogin: false, // Přidáno pro zobrazení login overlay
    showPlayRequest: false, // Přidáno pro zobrazení login overlay
    needRefetch: false, // Přidáno
    dressColors:[
      'black-gold',
      'black-red',
      'blue-orange',
      'blue-white',
      'gold-blue',
      'grey-green',
      'mh-blue',
      'mh-white',
      'orange-blue',
      'red-black',
      'red-white',
      'white-black',
      'white-blue',
      'white-gold',
      'white-green',
      'white-red'
    ]
  }),

  actions: {
    triggerRefetch() {
      this.needRefetch = true;
    },
    
    clearRefetchFlag() {
      this.needRefetch = false;
    },

    connect() {
      this.socket = useSocketService();

      if (this.socket) {
        this.socket.on('masterData', (masters: any) => {
         // console.log('masterData', masters);
          this.masters = masters;
          this.totalMasters = masters.length;
          this.onlineMasters = masters.filter((master: any) => master.online).length;
        });
      }
    },

    disconnect() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
    },

    sendMessage(message: Message) {
      if (this.socket) {
        this.socket.emit('message', message);
      }
    },

    // Přidána logika pro login overlay
    showLoginOverlay() {
      this.showLogin = true;
    },

    closeLoginOverlay() {
      this.showLogin = false;
    },

    showPlayRequestOverlay() {
      this.showPlayRequest = true;
    },

    closePlayRequest() {
      this.showPlayRequest = false;
    }
  },

  getters: {
    isLoginVisible: (state) => state.showLogin, // Getter pro stav zobrazení login overlay
    isPlayRequestVisible: (state) => state.showPlayRequest, // Getter pro stav zobrazení login overlay
  },
});
